import 'jquery-hoverintent/jquery.hoverIntent';
import { onReady } from '@/utils.ts';

/**
 * Close an opened drop-down menu
 *
 * @param target
 */
export const hideMenu = (target: EventTarget | HTMLElement) => {
    // No longer listen for keyboard ESC to close menu
    $('body').off('keyup.megamenu');

    const li = $(target);

    li.find('.dropdown-toggle').attr('aria-expanded', 'false');

    li.find('.dropdown-menu')
        .stop()
        .fadeTo(150, 0, function () {
            // can't just do a simple .hide(), conflicts with Bootstrap3 collapsed nav
            $(this)
                .css('display', '')
                .css('opacity', '');
        });
};


/**
 * Show one of the drop-down menus
 *
 * @param target
 */
export const showMenu = (target: EventTarget | HTMLElement) => {
    const li = $(target);

    li.find('.dropdown-toggle').attr('aria-expanded', 'true');

    li.find('.dropdown-menu')
        .stop()
        .fadeTo(150, 1)
        .show(400, () => {
            // Setup to listen for keyboard ESC to close opened menu
            //
            $('body').on('keyup.megamenu', (keyEvent) => {
                if (keyEvent.key === 'Escape') {
                    hideMenu(target);
                }
            });
        });
};


/**
 * Close all currently opened drop-downs, in case something was opened
 * by keyboard navigation
 */
const closeAll = () => {
    document.querySelectorAll<HTMLElement>('#main-menu .dropdown-toggle[aria-expanded=true]')
        .forEach((a) => {
            if (a.parentElement instanceof HTMLLIElement) {
                hideMenu(a.parentElement);
            }
        });
};


const megamenu_config = {
    sensitivity: 2, // sensitivity threshold (must be 1 or higher)
    interval: 80, // milliseconds for onMouseOver polling interval - was 100, julie changed to 10
    timeout: 50, // milliseconds delay before onMouseOut - was 500, julie changed to 50

    // function = onMouseOver callback (REQUIRED)
    over(event: Event) {
        if (event.currentTarget && window.matchMedia('(min-width: 768px)').matches) {
            closeAll();
            showMenu(event.currentTarget);
        }
    },

    // function = onMouseOut callback (REQUIRED)
    out(event: Event) {
        if (event.currentTarget && window.matchMedia('(min-width: 768px)').matches) {
            hideMenu(event.currentTarget);
        }
    },
};


/**
 * Setup the Megamenu behavior
 *
 */
onReady(() => {
    // Megamenu hover only if doing bootstrap3 multi-columns
    $('.navbar-nav .dropdown').hoverIntent(megamenu_config);
});
